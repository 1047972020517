body {
  padding: 0;
  margin: 0;
  background-color: #18be8a;
  color: #fff;
  font-family: 'Raleway', sans-serif;
  height: 100%;
}

.App {
  width: 80%;
  margin: auto;
}

.App .header {
  margin: 0 auto;
  max-width: 800px;
}

.App .header ul {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  list-style-type: none;
}

.App li {
  padding: 2rem 1.5rem;
  font-size: 1.2rem;
}

.App li a {
  text-decoration: none;
  color: #000;
}

.App li a:hover {
  color: #fff;
}

.App h2 {
  font-size: 2rem;
  font-weight: 200;
  line-height: 3rem;
  text-align: center;
}

.App h1 {
  font-size: 5rem;
  font-weight: 400;
  line-height: 1rem;
  text-align: center;
}

.App .vmiddle {
  margin: auto;
  padding: 20px;
  max-width: 800px;
}

.App .Footer {
  bottom: 30px;
  font-size: 1.2rem;
  text-align: center;
}

.privacy {
  max-width: 800px;
  margin: 0 auto;
}

.privacy h1 {
  margin: 0 auto 50px 0;
  text-align: left;
  line-height: 4rem;
}

.privacy li {
  padding: 0.4rem;
}

.strong {
  font-weight: bold;
}

.App .home-header li a.home, .App .privacy-header li a.privacy {
  color: #fff;
}
